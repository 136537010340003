import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"

import Layout from "../components/main-layout.js"
import Row from "../components/row.js"
import HeaderImage from "../components/elements/header-image.js"
import BlockQuote from "../components/elements/block-quote.js"

const Page = ({ location, data }) => {

return (
  <>
    <SEO title="Home" location={location} />
    <Layout location={location}>
      <Row xFull hFull noSeparated>
        <HeaderImage image={data.logo.childImageSharp.fluid}>
          <div className="flex w-full h-full items-center justify-center">
            <BlockQuote
              className="max-w-3/4 bg-white-tr-10 p-2"
              quote="All our dreams can come true, if we have the courage to pursue them."
              footer="Walt Disney"
            />
          </div>
        </HeaderImage>
      </Row>
    </Layout>
  </>
)
}
export const query = graphql`
  {
    logo: file(relativePath: { eq: "abstract-1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Page
